<template>
  <v-date-picker v-if="isRange" v-model="pickerRange" is-range>
    <template v-slot="{ inputValue, togglePopover }">
      <div class="date-picker" :style="style">
        <label v-if="label">{{ label }}</label>
        <div class="input">
          <input @click="togglePopover" :placeholder="pickerPh" :value="pickerRangeValue || rangeInputValue(inputValue)"
                 readonly/>
          <div class="ez-icon-date-range" @click="togglePopover"/>
        </div>
        <span v-if="error" class="has-error animated headShake">{{ $t(error) }}</span>
        <div v-if="validator && validator.$error && validator.hasOwnProperty('required')">
          <span class="has-error animated headShake" v-if="!validator.required">{{ $t('required_field') }}</span>
        </div>
      </div>
    </template>
  </v-date-picker>

  <v-date-picker v-else v-model="pickerDate" :mode="type">
    <template v-slot="{ inputValue, togglePopover }">
      <div class="date-picker" :style="style">
        <label v-if="label">{{ label }}</label>
        <div class="input">
          <input @click="togglePopover" :placeholder="pickerPh" :value="pickerValue || inputValue" readonly/>
          <div class="ez-icon-calendar" @click="togglePopover"/>
        </div>
        <span v-if="error" class="has-error animated headShake">{{ $t(error) }}</span>
        <div v-if="validator && validator.$error && validator.hasOwnProperty('required')">
          <span class="has-error animated headShake" v-if="!validator.required">{{ $t('required_field') }}</span>
        </div>
      </div>
    </template>
  </v-date-picker>
</template>

<script>
import Dh from '@/helpers/date.helper';

export default {
  name: "Datepicker",

  props: {
    type: {type: String, default: 'date', enum: ['date', 'dateTime']},
    isRange: {type: Boolean, default: false},
    label: {type: String},
    placeholder: {type: String},
    value: {type: [String, Date, Object]},
    validator: {type: Object},
    error: {type: String},

    bg: {type: String, default: 'white'},
    width: {type: String, default: '100%'},
    height: {type: String, default: '35px'},
    border: {type: String, default: '1px'},
    borderRadius: {type: String, default: '3px'},
    borderColor: {type: String, default: '#E7E8F2'},
    padding: {type: String, default: '.3rem .5rem'},
    boxShadow: {type: String, default: '0 3px 12px'},
    fSize: {type: String, default: '.8rem'},
    fColor: {type: String, default: '#495057'},
    phFSize: {type: String, default: '.8rem'},
    phColor: {type: String, default: "#8798AD"},
    iconSize: {type: String, default: '1.15rem'},
    iconColor: {type: String, default: '#495057'},
  },

  data() {
    return {
      pickerDate: '',
      pickerRange: {
        start: '',
        end: '',
      },
    }
  },

  computed: {
    pickerPh() {
      return this.placeholder || (this.isRange ? this.$t('range_placeholder') : this.$t('date_placeholder'));
    },

    pickerValue() {
      if (!this.value) {
        return null;
      } else if (this.type === 'date') {
        return Dh.customFormat(this.value, "MM/DD/YYYY")
      } else {
        return Dh.customFormat(this.value, "MM/DD/YYYY h:mm A")
      }
    },

    pickerRangeValue() {
      if (this.value && (this.value.start && this.value.end))
        return `${Dh.customFormat(this.value.start, "MM/DD/YYYY")} - ${Dh.customFormat(this.value.end, "MM/DD/YYYY")}`;
      else
        return null;
    },

    style() {
      return {
        '--bg': this.bg,
        '--width': this.width,
        '--height': this.height,
        '--border': this.border,
        '--border-color': this.borderColor,
        '--border-radius': this.borderRadius,
        '--padding': this.padding,
        '--box-shadow': this.boxShadow,
        '--font-size': this.fSize,
        '--font-color': this.fColor,
        '--placeholder-size': this.phFSize,
        '--placeholder-color': this.phColor,
        '--icon-size': this.iconSize,
        '--icon-color': this.iconColor,
      }
    }
  },

  mounted() {
    this.validateDateFormat();
  },

  methods: {
    validateDateFormat() {
      if (!this.value) {
        // Do nothing
      } else if (this.isRange) {
        if (typeof this.value === 'object' && !(this.value instanceof Date)) {
          if (this.value.start && this.value.end) {
            this.pickerRange = this.value;
          }
        } else {
          throw 'Date range is not an object!';
        }
      } else {
        if (typeof this.value === 'string' || this.value instanceof Date) {
          this.pickerDate = this.value
        } else {
          throw 'Date value is not a string or a Date instance!';
        }
      }
    },

    rangeInputValue(inputValue) {
      if (inputValue.start && inputValue.end)
        return `${inputValue.start} - ${inputValue.end}`;
      else
        return null;
    },
  },

  watch: {
    value(value) {
      this.pickerDate = value
    },

    pickerDate(date) {
      if (this.type === 'date') {
        this.$emit('input', date ? Dh.customFormat(date) : '');
        this.$emit('change', date ? Dh.customFormat(date) : '');
      }

      if (this.type === 'dateTime') {
        this.$emit('input', date ? date.toString() : '');
        this.$emit('change', date ? date.toString() : '');
      }
    },

    pickerRange(obj) {
      obj.start = Dh.customFormat(obj.start);
      obj.end = Dh.customFormat(obj.end);

      this.$emit('input', obj);
      this.$emit('change', obj);
    },
  }
}
</script>

<style lang="scss">
.date-picker {
  position: relative;
  width: var(--width);

  label {
    display: block;
    margin-bottom: 5px;
    color: color(bg-black3);
    font-size: .7rem;
    font-weight: $font-medium;
  }

  .input {
    position: relative;

    input {
      background: var(--bg);
      border: var(--border) solid var(--border-color);
      border-radius: var(--border-radius);
      color: var(--font-color);
      height: var(--height);
      font-size: var(--font-size);
      padding: var(--padding);
      transition: .15s ease-in-out;
      cursor: default;
      width: 100%;

      &:focus {
        box-shadow: var(--box-shadow) rgba(color(bg-gray2), .3);
      }

      &::placeholder,
      &:-ms-input-placeholder,
      &::-ms-input-placeholder {
        color: color(bg-black3);
        font-size: .8rem;
      }
    }

    .ez-icon-calendar,
    .ez-icon-date-range {
      height: var(--height);
      padding: var(--padding);
      color: var(--icon-color);
      font-size: var(--icon-size);
      cursor: default;
      position: absolute;
      display: flex;
      align-items: center;
      right: 0;
      top: 0;
    }
  }

  .has-error {
    position: absolute;
    bottom: -14px;
    font-size: .7rem;
    right: 5px;
    color: color(bg-red);
    font-weight: $font-medium;
  }

  @keyframes bounce {
    from,
    20%,
    53%,
    80%,
    to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    40%,
    43% {
      -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      -webkit-transform: translate3d(0, -30px, 0);
      transform: translate3d(0, -30px, 0);
    }

    70% {
      -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      -webkit-transform: translate3d(0, -15px, 0);
      transform: translate3d(0, -15px, 0);
    }

    90% {
      -webkit-transform: translate3d(0, -4px, 0);
      transform: translate3d(0, -4px, 0);
    }
  }

  .bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce;
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
  }
}
</style>

<i18n scoped>
{
  "en": {
    "date_placeholder": "Pick a date",
    "range_placeholder": "Pick a date range",
    "Invalid field": "Invalid field",
    "Required field": "Required field",
    "Invalid field, select at least one": "Invalid field, must select almost one",
    "Invalid field, must be greater": "Invalid field, must be greater",
    "required_field": "Required field",
    "out_of_schedule": "Out of schedule"
  },
  "es": {
    "date_placeholder": "Seleccione una fecha",
    "range_placeholder": "Elija un rango de fechas",
    "Invalid field": "Campo no válido",
    "Required field": "Campo obligatorio",
    "Invalid field, select at least one": "Campo no válido, debe seleccionar al menos una opción",
    "Invalid field, must be greater": "Campo no válido, debe ser mayor",
    "required_field": "Campo obligatorio",
    "out_of_schedule": "Fuera de horario"
  }
}
</i18n>